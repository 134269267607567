import React from 'react';
import FullpageSection from "../fullpageSection"
import BackgroundHomepageMoreThanBeer from "../backgroundImages/backgroundHomepageMoreThanBeer"

import {resolveLinkToRoute} from "../../helpers";

import contents from "./fullpageSectionHomepageMoreThanBeer.yml";

const FullpageSectionHomepageMoreThanBeer = () => {

  const {
    titleTag,
    title,
    subtitle,
    text,
    link
  } = contents;

  return (
    <FullpageSection
      whichTitle={titleTag}
      copyTitle={title}
      haveSubtitle={!!(subtitle && subtitle !== '')}
      copySubtitle={subtitle}
      copyText={text}
      haveCTA={!!(link && link.to && link.to !== '')}
      buttonLinkTo={link && resolveLinkToRoute(link.to)}
      buttonLinkTarget={link && link.target}
      buttonLinkLabel={link && link.label}
      buttonLinkTrackingCategory={link && link.trackingCategory}
    >
      <BackgroundHomepageMoreThanBeer />
    </FullpageSection>
  )
};

export default FullpageSectionHomepageMoreThanBeer;
