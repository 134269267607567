import React from "react"
import Swiper from "react-id-swiper"
import { ParallaxBanner } from "react-scroll-parallax"
import FitText from "@kennethormandy/react-fittext"
import ButtonLink from "../buttonLink"
import BackgroundImg from "../backgroundImg"
import {graphql, useStaticQuery} from "gatsby"
import CONST from "../../config/constants"

import 'swiper/css/swiper.css'
import "./swiper-homepage.scss"
import {compareDisplayOrderDesc} from "../../helpers";

const SwiperHomepageDesktop = () => {

  const data = useStaticQuery(graphql`
    query {
      cmsApi {
        getHpSlideDesktopListing {
          edges {
            node {
              displayOrder
              hpSlideTitle
              hpSlideTitleCompressor
              hpSlideCta {
                text
                path
              }
              hpSlideImage {
                fullpath
              }
            }
          }
        }
      }
    }
  `);
  const edges = data.cmsApi.getHpSlideDesktopListing.edges;
  edges.sort(compareDisplayOrderDesc);
  const swiperParams = {
    spaceBetween: 0,
    a11y: {
      prevSlideMessage: 'Slide precedente',
      nextSlideMessage: 'Slide successiva',
      firstSlideMessage: 'Questa è la prima slide',
      lastSlideMessage: 'Questa è l’ultima slide'
    },
    speed: 1000,
    watchOverflow: true,
    watchSlidesVisibility: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    }
  };

  // useEffect(()=>{
  //   [...document.querySelectorAll(".title-fit-width")].forEach(el => {
  //     el.style.setProperty("--length", el.innerText.length);
  //   });
  // });

  return (
    <div className="item--desktop-only">

      <Swiper containerClass="swiper-container swiper-container--homepage" {...swiperParams}>

        { edges.map(({node},i)=>{

          const {
            hpSlideTitle,
            hpSlideTitleCompressor,
            hpSlideCta,
            hpSlideImage
          } = node;

          return (
            <div
              key={i}
              className="swiper-slide"
            >
              <ParallaxBanner
                className="full-height-banner"
                style={{
                  height: '100vh',
                }}
                layers={[
                  {
                    children: (
                      <div className="title__wrapper">
                        <div className="title-fit-width">
                          <FitText
                            compressor={hpSlideTitleCompressor}
                          >
                            {hpSlideTitle}
                          </FitText>
                        </div>
                      </div>
                    ),
                    amount: 0.2,
                    expanded: false
                  },
                  {
                    children: (
                      <BackgroundImg
                        className="inherit-height contain"
                        alt={hpSlideTitle}
                        src={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${hpSlideImage.fullpath}`}
                      />
                    ),
                    amount: 0,
                  }
                ]}
              />
              {hpSlideCta &&
                <div className="button__wrapper">
                  <ButtonLink
                    to={hpSlideCta.path}
                    label={hpSlideCta.text}
                    trackingCategory={CONST.EVENT_TRACKING.HPSLIDE_CTA_CLICK}
                    trackingLabel={hpSlideCta.text}
                  />
                </div>
              }
            </div>
          );
        })}
      </Swiper>
    </div>
  );
};


export default SwiperHomepageDesktop;
