import React from 'react';

import contents from "./scrollDown.yml";

import "./scroll-down.scss";

const ScrollDown = () => {

  return (
    <div className="scroll-down__wrapper">
      <div className="scroll-down__element"/>
      <div className="scroll-down__text color--blue nowrap">
        {contents.scrollDown}
      </div>
    </div>
  )
};

ScrollDown.propTypes = {

};

export default ScrollDown;
