import React from "react"

import SEO from "../components/seo"
import ScrollDown from "../components/scrollDown"
import SwiperHomepageDesktop from "../components/swiperHomepage/swiperHomepageDesktop";
import SwiperHomepageMobile from "../components/swiperHomepage/swiperHomePageMobile";
import FullpageSectionHomepageMoreThanBeer from "../components/fullpageSectionHomepageMoreThanBeer"
import OnTheWeb from "../components/onTheWeb";

const IndexPage = () => (
  <main>
    <SEO
      title="Home page"
      description="Ceres non è solo una birra. Ceres è un mondo fatto di web, strada e piccolo schermo."
      keywords={["Ceres","Birra"]}
    />
    <ScrollDown />
    <SwiperHomepageDesktop />
    <SwiperHomepageMobile />
    <FullpageSectionHomepageMoreThanBeer />
    <OnTheWeb/>
  </main>
);

export default IndexPage;
