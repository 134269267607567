import {graphql, useStaticQuery} from "gatsby";
import React from "react";
import Swiper from "react-id-swiper";
import FitText from "@kennethormandy/react-fittext"
import BackgroundImg from "../backgroundImg";
import ButtonLink from "../buttonLink";
import CONST from "../../config/constants";

import 'swiper/css/swiper.css';
import "./swiper-homepage.scss";
import {compareDisplayOrderDesc} from "../../helpers";

const findFirstSlide = (slides) => {
  let firstSlide = "0";
  slides.forEach((slide, index)=>{
    if(slide.node.hpSlideMobileFirstSlide === true) {
      firstSlide = `${index}`;
    }
  });
  return firstSlide;
}

const SwiperHomepageMobile = () => {

  const data = useStaticQuery(graphql`
    query {
      cmsApi {
        getHpSlideMobileListing {
          edges {
            node {
              displayOrder
              hpSlideMobileTitle
              hpSlideMobileFirstSlide
              hpSlideMobileCta {
                text
                path
              }
              hpSlideMobileImage {
                fullpath
              }
            }
          }
        }
      }
    }
  `);
  const edges = data.cmsApi.getHpSlideMobileListing.edges;
  edges.sort(compareDisplayOrderDesc);
  const swiperParams = {
    slidesPerView: 2,
    spaceBetween: '-5%',
    a11y: {
      prevSlideMessage: 'Slide precedente',
      nextSlideMessage: 'Slide successiva',
      firstSlideMessage: 'Questa è la prima slide',
      lastSlideMessage: 'Questa è l’ultima slide'
    },
    speed: 1000,
    watchOverflow: true,
    watchSlidesVisibility: true,
    centeredSlides: true,
    slideToClickedSlide: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev"
    },
    activeSlideKey: findFirstSlide(edges)
  };

  return (
    <div className="item--mobile-only">
      {/* <div className="title__wrapper">
        <div className="title-fit-width">
          <FitText compressor={0.85}>
            Foto di famiglia
          </FitText>
        </div>
      </div>*/}


      <Swiper containerClass="swiper-container swiper-container--homepage" {...swiperParams}>

        { edges.map(({node},i)=> {

          const {
            hpSlideMobileTitle,
            hpSlideMobileCta,
            hpSlideMobileImage
          } = node;

          return (
            <div
              key={i}
              className="swiper-slide"
            >
              <div className="full-height-banner">
                <BackgroundImg
                  className="inherit-height"
                  alt={hpSlideMobileTitle}
                  src={`${process.env.GATSBY_CMS_ASSETS_BASEURL}${hpSlideMobileImage.fullpath}`}
                />
              </div>
              {hpSlideMobileCta &&
              <div className="button__wrapper">
                <ButtonLink
                  to={hpSlideMobileCta.path}
                  label={hpSlideMobileCta.text}
                  trackingCategory={CONST.EVENT_TRACKING.HPSLIDE_CTA_CLICK}
                  trackingLabel={hpSlideMobileCta.text}
                />
              </div>
              }
            </div>
          );

        })}

      </Swiper>
    </div>
  );
};

export default SwiperHomepageMobile;
